import JobTypeSelect, {
    JobTypeSelectProps,
} from "components/inputs/Select/JobTypeSelect"
import { singleSelectValidator } from "utils/validators"

const FIELD_NAME = "currentJobType" as const

const CurrentJobTypeField = (props: Omit<JobTypeSelectProps, "isMulti">) => {
    return (
        <JobTypeSelect
            name={FIELD_NAME}
            label="Current Role"
            placeholder="Search for your job"
            isMulti={false}
            closeMenuOnSelect
            {...props}
        />
    )
}

CurrentJobTypeField.fieldName = FIELD_NAME
CurrentJobTypeField.validators = {
    required: {
        [FIELD_NAME]: singleSelectValidator({ required: true }),
    },
}

export { CurrentJobTypeField }
