import CompanySelect, {
    CompanySelectProps,
} from "components/inputs/Select/CompanySelect"
import { singleSelectValidator } from "utils/validators"

const FIELD_NAME = "recentEmployer" as const

const CurrentCompanyField = (props: CompanySelectProps) => {
    return (
        <CompanySelect
            name={FIELD_NAME}
            label="Company name"
            placeholder="Search for your company"
            isMulti={false}
            closeMenuOnSelect
            {...props}
        />
    )
}

CurrentCompanyField.fieldName = FIELD_NAME
CurrentCompanyField.validators = {
    required: {
        // Both calls to nullable are necessary.
        [FIELD_NAME]: singleSelectValidator({ required: false }).when(
            "hasNoWorkExperience",
            {
                is: false,
                then: singleSelectValidator({ required: true }),
            }
        ),
    },
}

export { CurrentCompanyField }
