import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "yearsOfExperience" as const

const CurrentExperienceField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            label="Years of Experience"
            name={FIELD_NAME}
            type="number"
            placeholder="5"
            {...props}
        />
    )
}

CurrentExperienceField.fieldName = FIELD_NAME
CurrentExperienceField.validators = {
    required: {
        [FIELD_NAME]: Yup.number()
            .required("Required")
            .min(0, "Must be greater than 0")
            .max(65, "Must be less than 65")
            // Using required() means null values will not validate. We include
            // nullable and the transform to have NaN's -> null, and get a friendly
            // required error message instead of verbose type warning message.
            .nullable()
            .transform((_, original) =>
                original === Number(original) ? original : null
            ),
    },
}

export { CurrentExperienceField }
