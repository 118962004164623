import { CheckboxField, CheckboxFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "hasNoWorkExperience" as const

const HasNoExperienceField = (props: CheckboxFieldProps) => {
    return (
        <CheckboxField
            name={FIELD_NAME}
            label="I'm looking for my first job"
            size="small"
            {...props}
        />
    )
}

HasNoExperienceField.fieldName = FIELD_NAME
HasNoExperienceField.validators = {
    required: {
        [FIELD_NAME]: Yup.boolean().required("Required"),
    },
}

export { HasNoExperienceField }
