import { ReactNode } from "react"
import { css, useTheme } from "@emotion/react"
import { Box, theme, hexToHSL } from "@pallet-hq/hegel"

type TalentStylizedOnboardingMessageTextProps = {
    children: ReactNode
}

export const TalentStylizedOnboardingMessageText = (
    props: TalentStylizedOnboardingMessageTextProps
) => {
    // @ts-ignore
    const { colors } = useTheme()
    const [h, s] = hexToHSL(colors.primary)
    return (
        <Box
            css={css`
                font-family: "MintGrotesk";
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                padding: ${theme.space.large};
                z-index: 1;
                color: hsl(${h}, ${s}%, 90%);
            `}
        >
            {props.children}
        </Box>
    )
}
