import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import { reqMaxCharValidator } from "utils/validators"
import * as Yup from "yup"

const FIELD_NAME = "currentTitle" as const

const CurrentTitleField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            name={FIELD_NAME}
            label="Title"
            placeholder="Senior Product Manager"
            {...props}
        />
    )
}

CurrentTitleField.fieldName = FIELD_NAME
CurrentTitleField.validators = {
    required: {
        [FIELD_NAME]: Yup.string().when("hasNoWorkExperience", {
            is: false,
            then: reqMaxCharValidator(40),
        }),
    },
}

export { CurrentTitleField }
