import { ReactNode } from "react"
import { Theme, hexToHSL } from "@pallet-hq/hegel"
import { css, useTheme } from "@emotion/react"

type TalentStylizedOnboardingMessageEmphasizedTextProps = {
    children: ReactNode
}

export const TalentStylizedOnboardingMessageEmphasizedText = (
    props: TalentStylizedOnboardingMessageEmphasizedTextProps
) => {
    const theme = useTheme() as Theme
    const [h, s] = hexToHSL(theme.colors.primary)

    return (
        <span
            css={css`
                font-family: "MintGrotesk";
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                color: hsl(${h}, ${s}%, 90%);
            `}
        >
            {props.children}
        </span>
    )
}
