import { Box } from "@pallet-hq/hegel"
import { ReactNode } from "react"

type TalentFormFieldsBoxProps = {
    children: ReactNode
}

export const TalentFormFieldsBox = (props: TalentFormFieldsBoxProps) => {
    return (
        <Box display="flex" flexDirection="column" gridGap="small" width="100%">
            {props.children}
        </Box>
    )
}
