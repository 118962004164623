import { Box } from "@pallet-hq/hegel"
import { ReactNode } from "react"

type TalentFormBoxProps = {
    children: ReactNode
}

export const TalentFormBox = (props: TalentFormBoxProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            padding="0px"
            gridGap="large"
            width={["100%", "484px"]}
        >
            {props.children}
        </Box>
    )
}
