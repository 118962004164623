import { ReactNode } from "react"
import { css, useTheme } from "@emotion/react"
import { Box, hexToHSL } from "@pallet-hq/hegel"
import { ReactComponent as PalletLogo } from "components/icons/logos/pallet/logomark-white.svg"
import { QuarterCircleAnimatedPattern } from "@pallet/components/QuarterCircleAnimatedPattern"

type TalentStylizedOnboardingMessagePanelProps = {
    message: ReactNode
}

export const TalentStylizedOnboardingMessagePanel = (
    props: TalentStylizedOnboardingMessagePanelProps
) => {
    // @ts-ignore
    const { colors } = useTheme()
    const [h, s] = hexToHSL(colors.primary)

    return (
        <div
            css={{
                background: `hsl(${h},${s}%,10%)`,
                height: "100%",
            }}
        >
            <Box
                css={css`
                    position: sticky;
                    height: 100%;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                `}
            >
                {props.message}
                <QuarterCircleAnimatedPattern />
                <Box
                    css={css`
                        position: absolute;
                        bottom: 32px;
                        right: 40px;
                    `}
                >
                    <PalletLogo height={24} />
                </Box>
            </Box>
        </div>
    )
}
